<template>
  <base-dialog :show="isLoading"  title="Loading..." fixed>
    <base-spinner></base-spinner>
  </base-dialog>

  <top-bar
          @emitSearch="emitedSearch"
          id="topBar"
          v-bind:show-search="showSearch"
          v-bind:menu-icon="iconCloseOpen2"
          background-style="rgb(255,255,255,0.6)"
          title-color="black"
          v-bind:title-text="MainTitleText"
        ></top-bar>

 
  <!-- dialog info --> 
 <Dialog v-model:visible="displayInfoDialog" modal  :style="infoDialogStyle" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header=""> 
        <h4> {{ InfoModalHeader }}</h4>
    </template>
  
            <span class="font-bold text-2xl block mb-2 mt-4"></span>
            <p class="mb-0">{{InfoModalMessage }}</p>
            <div class="flex text-end mt-4">
                <Button label="Ok" @click="displayInfoDialog=false"></Button>
            </div>          
</Dialog>

  <!-- dialog potvrda izazivanja --> 
  <Dialog v-model:visible="displayPotvrdaDialog" modal  :style="infoDialogStyle" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <template #header=""> 
        <h4> potvrda</h4>
    </template>
            
            <span class="font-bold text-2xl block mb-2 mt-4"></span>
            <p class="mb-0">Jeste li sigurni da želite izazvati igrača: <b>{{ imeKogaIzaziva }} {{ prezimeKogaIzaziva }}</b> ?</p>
            
            <div class="row" style="margin-top:20px">
              <div class="col-md-6">
                <Button severity="danger" raised label="Odustani" @click="displayPotvrdaDialog=false"></Button>
              </div>

              <div class="col-md-6 text-end ">
                <Button label="Da" raised  @click="stvarnoIzaziva()"></Button>
              </div>
            </div>
                                
                 
</Dialog>

  <!-- dialog potvrda ok --> 
  <Dialog v-model:visible="displayPotvrdaDialogOk" modal  :style="infoDialogStyle" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    
            
            <span class="font-bold text-2xl block mb-2 mt-4"></span>
            <p class="mb-0">Uspješno ste izazvali igrača</p>
            
            <div class="row" style="margin-top:20px">
             
              <div class="col-md-6 text-end ">
                <Button label="OK" raised  @click="displayPotvrdaDialogOk=false"></Button>
              </div>
            </div>
                                
                 
</Dialog>


<TabView >
    
    <TabPanel header="Novosti">
       <div class="row">
          <skracene-novosti @clickNaNovost="clickNaNovost(post.id)" v-for="post in novosti" :key="post.id" :naslov="post.naslov" :datum="post.datum" :skraceno="post.skraceno"></skracene-novosti>
       </div>
    </TabPanel>

    
    
    
    
    <TabPanel header="Piramida">
      
      <div class="text-center" v-if="mozeIzazivati">
         <div class="alert alert-info">Možete izazivati igrače označene lopticom <img src="images/teniskaloptica.png" style="width:25px;height:25px"></div>
      </div>
      
      
      <div class="alert alert-danger text-center" v-if="prikaziAlert">{{ kogaMoze[0]['nemoze'] }} 
           <br>
            <Button @click="dialogEditRezultata=true" v-if="showEditButton">Promjena rezutata</Button>
      </div>
     
      <div class="alert alert-info text-center" v-if="prikaziDogovoren">
          <div class="malinaslov">Imate dogovoren meč</div>
          <div class="prikazMeca">{{ kogaMoze[0]['izazivac'] }} - {{ kogaMoze[0]['protivnik'] }}</div>
          <div><Button @click="dialogUpisRezultata=true">Upiši rezultat</Button></div>
      </div>

      <div class="alert alert-info text-center" v-if="prikaziDogovorenOdigran">{{ kogaMoze[0]['nemoze'] }} 
      
      </div>
      
      
      <div id="ssdd">
      <div id="piramida" v-html="piramidaHTML" @click="click"></div>
        </div>

    </TabPanel>


    <TabPanel header="Raspored i rezultati">
      <DataTable    :id="dataTable" :value="raspored" :paginator="true" :rows="25"   dataKey="id"  stripedRows v-model:filters="filters" filterDisplay="menu" key="key" 
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            paginatorStart="CurrentPageReport"
            :rowsPerPageOptions="[25,50,100,500]" responsiveLayout="scroll"
            currentPageReportTemplate="Prikazano od {first} do {last} od ukupno {totalRecords} utakmica"
            selectionMode="single"
          
            :globalFilterFields="['izazivac','protivnik','skupa','skupa2']"           
            scrollable 
            :scrollHeight="tableHeight"
            >
     
            <template #header>
                <div class="row justify-content-end">
                  <div class="col-md-4 text-end ">
                    
                        <InputText v-model="filters['global'].value" placeholder="Pretraživanje" />
                  
                    </div>
                </div>
            </template>      

            <Column field="izazivac" header="Izazivač" :sortable="true"  bodyStyle="text-align: left" style="min-height:43px;min-width:160px">
        <template #body="slotProps">
                   <div :class="stockClass(slotProps.data)" >
                   <span :class="stockClass2(slotProps.data)"> {{ slotProps.data.izazivac }}</span>
                </div>
            </template>
        
        
        
      </Column> 

      <Column field="protivnik" header="Protivnik" :sortable="true" bodyStyle="text-align: left" style="min-height:43px;min-width:160px">
        
            <template #body="slotProps">
                    <div :class="stockClass(slotProps.data)">
                    <span :class="stockClass3(slotProps.data)"> {{ slotProps.data.protivnik }}</span>
                </div>
            </template>

       
      </Column> 

      <Column field="skupa" header="skupa"  :sortable="false" bodyStyle="text-align: left" style="display:none;min-height:43px;min-width:160px">
      </Column> 
     
      <Column field="skupa2" header="skup2a"  :sortable="false" bodyStyle="text-align: left" style="display:none;min-height:43px;min-width:160px">
      </Column> 
      
      <Column field="rezultat" header="Rezultat"  bodyStyle="text-align: left" style="min-height:43px;;min-width:160px">
            <template #body="slotProps">
                
              
              <div :class="stockClass(slotProps.data)">
                    {{ slotProps.data.rezultat }}
                </div>
            </template>  
        
        
      </Column> 



     <Column field="datum_izazivanja" :sortable="true" sortField="unix2" header="Datum izazivanja" bodyStyle="text-align: left" style="min-height:43px;min-width:100px;">
            <template #body="slotProps">
                  <div :class="stockClass(slotProps.data)">
                    {{ slotProps.data.datum_izazivanja }}
                </div>
            </template>
      
         
      </Column>       
      <Column field="datum_igranja" header="Datum igranja" :sortable="true" sortField="unix1"  bodyStyle="text-align: left" style="min-height:43px;;min-width:100px">
        <template #body="slotProps">
                  <div :class="stockClass(slotProps.data)">
                    {{ slotProps.data.datum_igranja }}
                    <div v-if="slotProps.data.kasni > 0">{{ slotProps.data.kasni }}</div>
                </div>
            </template>
        
        
      </Column>  

    

       


     </DataTable>

    </TabPanel>
    
    <TabPanel header="Faraon">
       
      <DataTable    :id="dataTableFaraon" :value="faraon" :paginator="false"  dataKey="id_igraca"  stripedRows v-model:filters="filters" filterDisplay="menu" key="id_igraca" 
           
            selectionMode="single"
                   
            scrollable 
            :scrollHeight="tableHeightFaraonCezar"
            >
     
            
            <template #header>
                <div class="row justify-content-end">
                  <div class="col-md-8 col-8" style="padding-left:0">
                   <Dropdown @change="mijenjaDatume" v-model="mjesec" :options="svimjeseci"  optionLabel="mjesec" optionValue="value" :placeholder="svimjeseci.value" style="width:120px"></Dropdown>&nbsp;
                   <Dropdown @change="mijenjaDatume" v-model="godina" :options="svegodine"   optionLabel="godina" optionValue="value" :placeholder="svegodine.value"></Dropdown>&nbsp;
                 <!-- <Button class="btn btn-primary btn-sm">Prikaži</Button>-->
                </div> 

                  <div class="col-md-4 col-4 text-end ">
                    
                        <InputText v-model="filters['global'].value" placeholder="Pretraživanje" style="width:100px"/>
                  
                    </div>
                </div>
            </template>      

            



      <Column class="boldani centrirano" field="mjesto" header="Mjesto"  bodyStyle="text-align: center" style="min-width:100px;min-height:43px;"></Column>
      <Column class="boldani ulijevo" field="igrac" header="Ime i prezime"  bodyStyle="text-align: left" style="min-width:200px;;min-height:43px;"></Column>   
      <Column class="boldani centrirano" field="kategorija" header="Kategorija"  bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;">
        <template #filter="{filterModel}">
          <Dropdown v-model="filterModel.value" :options="sveKategorije" filter optionLabel="kategorija" optionValue="kategorija" placeholder="Odaberi kategoriju"></Dropdown>
        </template>
      
      </Column> 

      <Column class="boldani centrirano" field="mjestoKategorija" header="Mj. kategorija"  bodyStyle="text-align: center" style="min-width:100px;min-height:43px;"></Column>

        
      
       
      
      
      <Column class="boldani centrirano" field="bodova" header="Prosječna pozicija" bodyStyle="text-align: center" styleClass="centeredColumnContent" style="min-width:100px;text-align: center;min-height:43px;">
      
      <template #body="{ data }">
      
        <div class="text-center">{{ data.bodova }}</div>
      </template>
      
      </Column>
      <Column class="boldani centrirano" field="utakmica" header="Mečeva" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
      <Column class="boldani centrirano" field="kazneni" header="Kazneni bodovi" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
      <Column class="boldani centrirano" field="ukupno" header="Ukupno" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
           
      
         
        

     </DataTable> 


    </TabPanel>

    <TabPanel header="Faraon ukupno">
       
       <DataTable    :id="dataTableFaraonKomplet" :value="faraonKomplet" :paginator="false"  dataKey="id_igraca"  stripedRows v-model:filters="filters" filterDisplay="menu" key="id_igraca" 
            
             selectionMode="single"
                    
             scrollable 
             :scrollHeight="tableHeightFaraonCezar"
             >
      
             
             <template #header>
             
              <div class="row">
                 <div class="col-md-6">
                    Poredak od  {{ datum_aktivacije }}
                  </div>
  
                    <div class="col-md-6  col-6 text-end ">
                     
                          <InputText v-model="filters['global'].value" placeholder="Pretraživanje" style="width:200px"/>
                    
                     </div>
                    </div>
                
             </template>      
 
             
 
 
 
       <Column class="boldani centrirano" field="mjesto" header="Mjesto"  bodyStyle="text-align: center" style="min-width:100px;min-height:43px;"></Column>  
       <Column class="boldani ulijevo" field="igrac" header="Ime i prezime"  bodyStyle="text-align: left" style="min-width:200px;;min-height:43px;"></Column>
       <Column class="boldani centrirano" field="kategorija" header="Kategorija"  bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"> 

        <template #filter="{filterModel}">
           <Dropdown v-model="filterModel.value" :options="sveKategorije" filter optionLabel="kategorija" optionValue="kategorija" placeholder="Odaberi kategoriju"></Dropdown>
         </template>
       
       </Column> 
       <Column class="boldani centrirano" field="mjestoKategorija" header="Mj. kategorija"  bodyStyle="text-align: center" style="min-width:100px;min-height:43px;"></Column>
       <Column class="boldani centrirano" field="mjeseci" header="Mjeseci igranja"  bodyStyle="text-align: center" style="min-width:100px;min-height:43px;"></Column>
       
      
    
       
       
       
       <Column class="boldani centrirano" field="bodova" header="Bodovi" bodyStyle="text-align: center" styleClass="centeredColumnContent" style="min-width:100px;text-align: center;min-height:43px;">
       
       <template #body="{ data }">
       
         <div class="text-center">{{ data.bodova }}</div>
       </template>
       
       </Column>
       <Column class="boldani centrirano" field="utakmica" header="Mečeva" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
       <Column class="boldani centrirano" field="kazneni" header="Kazneni bodovi" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
       <Column class="boldani centrirano" field="ukupno" header="Ukupno" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column> 
            
       
          
         
 
      </DataTable> 
 
 
     </TabPanel>


    <TabPanel header="Cezar">
        
      <DataTable    :id="dataTableCezar" :value="cezar" :paginator="false"  dataKey="id_igraca"  stripedRows v-model:filters="filters" filterDisplay="menu" key="id_igraca" 
           
           selectionMode="single"
                  
           scrollable 
           :scrollHeight="tableHeightFaraonCezar"
           >
           <template #header>
                <div class="row justify-content-end">
                  <div class="col-md-8  col-8" style="padding-left:0">
                   <Dropdown @change="mijenjaDatume" v-model="mjesec" :options="svimjeseci"  optionLabel="mjesec" optionValue="value" :placeholder="svimjeseci.value" style="width:120px"></Dropdown>&nbsp;
                   <Dropdown @change="mijenjaDatume" v-model="godina" :options="svegodine"   optionLabel="godina" optionValue="value" :placeholder="svegodine.value"></Dropdown>&nbsp;
                  <!-- <Button class="btn btn-primary btn-sm">Prikaži</Button>-->
                </div> 

                  <div class="col-md-4  col-4 text-end ">
                    
                        <InputText v-model="filters['global'].value" placeholder="Pretraživanje" style="width:100px"/>
                  
                    </div>
                </div>
            </template>         
           

           <Column class="boldani centrirano" field="mjesto" header="Mjesto"  bodyStyle="text-align: center" style="min-width:100px;max-width:100px;min-height:43px;"></Column>
           <Column class="boldani ulijevo" field="igrac" header="Ime i prezime"  bodyStyle="text-align: left" style="min-width:200px;;min-height:43px;"></Column>   
           <Column class="boldani centrirano" field="kategorija" header="Kategorija"  bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;">
        
        <template #filter="{filterModel}">
          <Dropdown v-model="filterModel.value" :options="sveKategorije" filter optionLabel="kategorija" optionValue="kategorija" placeholder="Odaberi kategoriju"></Dropdown>
        </template>
      
      </Column>    
           <Column class="boldani centrirano" field="mjestoKategorija" header="Mj.kategorija"  bodyStyle="text-align: center" style="min-width:100px;max-width:100px;min-height:43px;"></Column>
    
     
      
      
     
     <Column class="boldani centrirano" field="utakmica" header="Mečeva" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;">
        <template #data>
          <div class="text-center">{{ data.data.utakmica }}</div>
        </template>
    
      </Column>    
     <Column class="boldani centrirano" field="bodova" header="Prosječna pozicija" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column>
     
    
          
     
        
       

    </DataTable> 


    </TabPanel>


    <TabPanel header="Cezar ukupno">
        
        <DataTable    :id="dataTableCezarKomplet" :value="cezarKomplet" :paginator="false"  dataKey="id_igraca"  stripedRows v-model:filters="filters" filterDisplay="menu" key="id_igraca" 
             
             selectionMode="single"
                    
             scrollable 
             :scrollHeight="tableHeightFaraonCezar"
             >
             <template #header>
                  
                 <div class="row">
              <div class="col-md-6">
                    Poredak od  {{ datum_aktivacije }}
                  </div>
  
                    <div class="col-md-6  col-6 text-end ">
                     
                          <InputText v-model="filters['global'].value" placeholder="Pretraživanje" style="width:200px"/>
                    
                     </div>
                    </div>
              </template>         
             
  
             <Column class="boldani centrirano" field="mjesto" header="Mjesto"  bodyStyle="text-align: center" style="min-width:100px;max-width:100px;min-height:43px;"></Column>
             <Column class="boldani ulijevo" field="igrac" header="Ime i prezime"  bodyStyle="text-align: left" style="min-width:200px;;min-height:43px;"></Column>   
        <Column class="boldani centrirano" field="kategorija" header="Kategorija"  bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;">
          
          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="sveKategorije" filter optionLabel="kategorija" optionValue="kategorija" placeholder="Odaberi kategoriju"></Dropdown>
          </template>
        
        </Column>    
             <Column class="boldani centrirano" field="mjestoKategorija" header="Mj.kategorija"  bodyStyle="text-align: center" style="min-width:100px;max-width:100px;min-height:43px;"></Column>
             <Column class="boldani centrirano" field="mjeseci" header="Mjeseci igranja"  bodyStyle="text-align: center" style="min-width:100px;max-width:100px;min-height:43px;"></Column>
      
       
        
       
       <Column class="boldani centrirano" field="utakmica" header="Mečeva" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;">
          <template #data>
            <div class="text-center">{{ data.data.utakmica }}</div>
          </template>
      
        </Column>    
       <Column class="boldani centrirano" field="bodova" header="Prosječna pozicija" bodyStyle="text-align: center" style="min-width:100px;text-align: center;min-height:43px;"></Column>
       
      
            
       
          
         
  
      </DataTable> 
  
  
      </TabPanel>

    <TabPanel header="Rating">
        
          
      <DataTable :id="dataTableRating" :value="rating" :paginator="false"  dataKey="id_igraca"  stripedRows v-model:filters="filters" filterDisplay="menu" key="id_igraca" 
           
           selectionMode="single"
                  
           scrollable 
           :scrollHeight="tableHeightFaraonCezar"
           >
    
           <template #header>
                <div class="row justify-content-end">
                  <div class="col-md-4 text-end ">
                    
                        <InputText v-model="filters['global'].value" placeholder="Pretraživanje" />
                  
                    </div>
                </div>
            </template>         

     <Column class="boldani centrirano" field="mjesto" header="Mjesto"  bodyStyle="text-align: left" style="max-width:100px;min-height:43px;"></Column>    
     <Column class="boldani ulijevo" field="igrac" header="Ime i prezime"  bodyStyle="text-align: left" style="min-height:43px;"></Column> 
     <Column class="boldani centrirano" field="utakmica" header="Mečeva" bodyStyle="text-align: center" style="text-align: center;min-height:43px;">
        <template #data>
          <div class="text-center">{{ data.data.utakmica }}</div>
        </template>
    
      </Column>    
     <Column class="boldani centrirano" field="rating" header="Rating" bodyStyle="text-align: center" style="text-align: center;min-height:43px;"></Column>

    </DataTable>

    </TabPanel>
   
    <TabPanel header="Rezervacija">
       <div class="row">
            <div class="col" v-html="iFrameRervacijeTermina"></div>
          
       </div>
    </TabPanel>

    <TabPanel header="Opis i pravila">
       <div class="row">
            <div class="col" v-html="opisIpravila"></div>
          
       </div>
    </TabPanel>

    


</TabView>


<Dialog id="dialogNovosti" v-model:visible="prikaziNovost" :value="novost" modal header="&nbsp;" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '90vw', '575px': '95vw' }">
 
  <template #header>
   <h4 class="inmodal"> {{novost[0]['naslov']}}</h4>
  
  </template>
      <h5 class="datum" v-html="novost[0]['datum']"></h5>
      <hr>
      <div v-html="novost[0]['text']"></div>


</Dialog>

<Dialog id="detaljiIgravca" v-model:visible="modalDetaljiIgraca" :value="podaciIgraca" modal header="&nbsp;" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '90vw', '575px': '95vw' }">
 
 <template #header>
  <h4 class="inmodal"> {{podaciIgraca['ime']}}  {{podaciIgraca['prezime']}}</h4>
 
 </template>
      
 <div v-if="mozeTogaIzazvati[podaciIgraca['id']]">
    <div class="alert alert-info text-center">
      <Button @click="izazivaIgraca(podaciIgraca['id'],podaciIgraca['ime'] ,podaciIgraca['prezime'])"><img src="images/teniskaloptica.png" style="width:22px;height:22px">Izazovi igraca</Button>
    </div>
 </div>
  

       <div class="row">
          <div class="col-sm-6 col-md-5 col-12" style="padding:0"><img class="slikaigraca" :src="DOMENA+'slike/'+podaciIgraca['slika']"></div>
          <div class="col-sm-6 col-md-7 col-12" style="padding:0">

             
            <table class="tablica">
                <tr>
                    <td  style="width:120px">Telefon:</td>
                    <td>
                      <strong v-if="this.$store.getters.isAuthenticated">{{podaciIgraca['telefon']}}</strong>
                      <strong v-if="!this.$store.getters.isAuthenticated">********</strong>
                    </td>
                </tr>
                <tr>
                    <td>Email:</td>
                    <td>
                      <strong v-if="this.$store.getters.isAuthenticated">{{podaciIgraca['email']}}</strong>
                      <strong v-if="!this.$store.getters.isAuthenticated">********</strong></td>
                </tr>
            </table>
            
            <table class="tablica">
                <tr>
                    <th colspan="2">Ova Piramida:</th>
               </tr>
               <tr>
                <td  style="width:120px" >Mečeva:</td>
                <td><strong>{{podaciIgraca['utakmica']}}</strong></td>
               </tr>

               <tr>
                <td>Pobjeda:</td>
                <td><strong>{{podaciIgraca['pobjeda']}}</strong> (<span>{{ postotakPobjeda }}</span>)</td>
               </tr>

               <tr>
                <td>Poraza:</td>
                <td><strong>{{podaciIgraca['poraza']}}</strong></td>
               </tr>

            </table>

            <table class="tablica">
                <tr>
                    <th colspan="2">Ukupno:</th>
               </tr>
               <tr>
                <td style="width:120px">Rating:</td>
                <td><strong>{{podaciIgraca['rating']}}</strong></td>
               </tr>

               <tr>
                <td>Mečeva:</td>
                <td><strong>{{podaciIgraca['ukupnoutakmica']}}</strong></td>
               </tr>

               <tr>
                <td>Pobjeda:</td>
                <td><strong>{{podaciIgraca['ukupnopobjeda']}}</strong> (<span>{{ postotakPobjedaUkupno }}</span>)</td>
               </tr>

               <tr>
                <td>Poraza</td>
                <td><strong>{{podaciIgraca['ukupnoporaza']}}</strong></td>
               </tr>

            </table>
                        
        
                
          </div>
       </div>



</Dialog>


<Dialog class="dialogUpisRezultata" v-model:visible="dialogUpisRezultata" :value="kogaMoze" modal header="&nbsp;" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '90vw', '575px': '95vw' }">
 
 <template #header>
  <h4 class="inmodal">Upis rezultata</h4>
 
 </template>
    
      
     
     <div class="row">
        <div class="col-5">Pobjednik</div>
        <div class="col-2 text-center" >1.set</div>
        <div class="col-2 text-center">2.set</div>
        <div class="col-2 text-center">3.set</div>
        <div class="col-1 text-center">predao</div>
    </div> 
    <hr>
    <div class="row">
        <div class="col-1"><Checkbox disabled  v-model="kliknuo_izazivaca" inputId="upis_id_izazivaca" name="upis_id_izazivaca"   :binary="true"/></div>
        <div class="col-4">{{ upis_izazivac }}</div>
        
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_1set_izazivac" :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_2set_izazivac"  :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_3set_izazivac" :min="0" :max="100" :disabled="disableTreciSet"/></div>
        <div class="col-1"><Checkbox   @click="clickCheckbox(1)"  v-model="predao_izazivac" inputId="upis_id_izazivaca" name="upis_id_izazivaca"   :binary="true"/></div>
      </div>
<br>
    <div class="row">
        <div class="col-1"><Checkbox disabled v-model="kliknuo_protivnika" inputId="upis_id_protivnika" name="upis_id_protivnika"   :binary="true"/></div>
        <div class="col-4">{{ upis_protivnik }}</div>
        
         
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_1set_protivnik" :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_2set_protivnik"  :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_3set_protivnik" :min="0" :max="100"  :disabled="disableTreciSet"/></div>
        <div class="col-1"><Checkbox  @click="clickCheckbox(2)" v-model="predao_protivnik" inputId="upis_id_protivnika" name="upis_id_protivnika"   :binary="true"/></div>
      </div>

     <hr>

     <div v-html="rezultat_opisno"></div>
     <div class="text-cemter">
        <Button @click="upisRezultata">Upiši rezultat</Button>
     </div>

     



</Dialog>



<Dialog class="dialogUpisRezultata" v-model:visible="dialogEditRezultata" :value="kogaMoze" modal header="&nbsp;" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '90vw', '575px': '95vw' }">
 
 <template #header>
  <h4 class="inmodal">Promjena rezultata</h4>
 
 </template>
    
      
     
     <div class="row">
        <div class="col-5">Pobjednik</div>
        <div class="col-2 text-center" >1.set</div>
        <div class="col-2 text-center">2.set</div>
        <div class="col-2 text-center">3.set</div>
        <div class="col-1 text-center">predao</div>
    </div> 
    <hr>
    <div class="row">
        <div class="col-1"><Checkbox disabled  v-model="kliknuo_izazivaca" inputId="upis_id_izazivaca" name="upis_id_izazivaca"   :binary="true"/></div>
        <div class="col-4">{{ upis_izazivac }}</div>
        
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_1set_izazivac" :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_2set_izazivac"  :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_3set_izazivac" :min="0" :max="100" :disabled="disableTreciSet"/></div>
        <div class="col-1"><Checkbox   @click="clickCheckbox(1)"  v-model="predao_izazivac" inputId="upis_id_izazivaca" name="upis_id_izazivaca"   :binary="true"/></div>
      </div>
<br>
    <div class="row">
        <div class="col-1"><Checkbox disabled v-model="kliknuo_protivnika" inputId="upis_id_protivnika" name="upis_id_protivnika"   :binary="true"/></div>
        <div class="col-4">{{ upis_protivnik }}</div>
        
         
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_1set_protivnik" :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_2set_protivnik"  :min="0" :max="100" /></div>
        <div class="col-2 text-center" style="padding-right: 30px;"><InputNumber @input="mijenjaRezultat" inputId="stacked-buttons" showButtons class="unosRezultata" v-model="rez_3set_protivnik" :min="0" :max="100"  :disabled="disableTreciSet"/></div>
        <div class="col-1"><Checkbox  @click="clickCheckbox(2)" v-model="predao_protivnik" inputId="upis_id_protivnika" name="upis_id_protivnika"   :binary="true"/></div>
      </div>

     <hr>

     <div v-html="rezultat_opisno"></div>
     <div class="text-cemter">
        <Button @click="upisRezultata">Spremi promjene</Button>
     </div>

     



</Dialog>


</template>

<script>

//import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
//import InlineMessage from 'primevue/inlinemessage';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';         // for filtering
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';

import moment from "moment";
import axios from "axios";
import $ from "jquery";

export default {
  components: {
        DataTable,
        Column,
        InputText,
        Checkbox,
        Button,
        Dialog,
      // InlineMessage,
      //  Calendar,
        InputNumber,
        TabView,
        TabPanel,
        
        Dropdown
  },

  data() {
    return {
      iFrameRervacijeTermina: '<iframe src="https://openresa.com/plannings-integrated?id=1631&key=a61131f6778f2775b3ce3a046835fd3508c05c4e798f39b7ace7d75126c535721e6711ecd7372faf#date=0" style="width: 100%; height: 700px; border: 0;"></iframe>',
      opisIpravila:'',
      datum_aktivacije:'',
      displayPotvrdaDialogOk:false,
      displayPotvrdaDialog:false,
      imeKogaIzaziva:'',
      prezimeKogaIzaziva:'',
      idKogaIzaziva:'',
      mozeTogaIzazvati:[],
      mozeIzazivati:false,
      dialogIzazivanje:false,
      postotakPobjeda:'',
      postotakPobjedaUkupno:'',
      DOMENA:'https://piramida.it-b.hr/',
      modalDetaljiIgraca:false,
      podaciIgraca:[],
      showEditButton:false,
      dialogEditRezultata:false,
      id_dogovoreno:'',
      id_pobjednika:'',
      predao_izazivac:false,
      predao_protivnik:false,
      id_igrane_utakmice:'',
      id_pobjenika:'',
      rezultat_opisno:'',
      rez_1set_izazivac:0,
      rez_2set_izazivac:0,
      rez_3set_izazivac:0,
      rez_1set_protivnik:0,
      rez_2set_protivnik:0,
      rez_3set_protivnik:0,
      disableTreciSet:true,
      faraonKomplet:[],
      cezarnKomplet:[],
      kliknuo_izazivaca:false,
      kliknuo_protivnika:false,
      dialogUpisRezultata:false,
      upis_id_izazivaca:'',
      upis_id_protivnika:'',
                  upis_izazivac:'',
                  upis_protivnik:'',
    
                  lista: [],
      
            prikaziAlert:false,
      prikaziDogovoren:false,
      prikaziDogovorenOdigran:false,
      kogaMoze:[],
      svimjeseci:[{"mjesec":"Siječanj","value":1},{"mjesec":"Veljača","value":2},{"mjesec":"Ožujak","value":3},{"mjesec":"Travanj","value":4},{"mjesec":"Svibanj","value":5},{"mjesec":"Lipanj","value":6},{"mjesec":"Srpanj","value":7},{"mjesec":"Kolovoz","value":8},{"mjesec":"Rujan","value":9},{"mjesec":"Listopad","value":10},{"mjesec":"Studeni","value":11},{"mjesec":"Prosinac","value":12}],
      svegodine:[],
      sveKategorije:[{"kategorija":"dd"}],
      novosti:[],
      novost:[],
      prikaziPopupNovost:false,
      faraon:[],
      cezar:[],
      rating:[],
      mjesec:'',
      godina:'',
      prikaziNovost:false,
        filters: {
               'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
               'protivnik':{operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}, 
               'izazivac': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]}, 
               'kategorija': {operator: FilterOperator.AND, constraints: [{value: null,  matchMode: FilterMatchMode.CONTAINS}]}, 
              
            },


      dataTable:'dataTable',
      dataTableFaraon:'dataTableFaraon',
      dataTableCezar:'dataTableCezar',
      dataTableFaraonKomplet:'dataTableFaraonKomplet',
      dataTableCezarKomplet:'dataTableCezarKomplet',

      dataTableRating:'dataTableRating',
      tableHeight:'calc( 100vh - 300px)',
      tableHeightFaraonCezar:'calc( 100vh - 200px)',
      raspored:[],
      piramidaHTML:'',
      showSearch: false,
      MainTitleText: this._rootAppTitle,
      TopBackgroundStyle: "blue",
      displayInfoDialog:false,
      infoDialogStyle:'',
      piramida:[],
      piramidaID:'',

      settings: [],

      //login:
      username: "",
      password: "",
      showUsernameAlert: false,
      showPasswordAlert: false,
      formIsValid: true,
      isLoading: false,
      error: null,

      //testing;
      token: "",
    };
  },

  methods: {

    stockClass(data){
      
        if(data.status_rezultata == 2){
          return("prekrizeno");
        }
    },

    stockClass2(data){
      
        if(data.rezultat_utakmice == 1){
          return("boldano");
        }
    },

    stockClass3(data){
      
      if(data.rezultat_utakmice == 2){
        return("boldano");
      }
  },

  mijenjaRezultat(){
 
    this.racunajRezultat();

      
  },

  upisRezultata(){
   
      /*
                     
                */


     //prvo joše jednom izracunaj rezultat za svaki slučaj
     this.racunajRezultat();

    
     if(this.id_pobjednika==''){
            this.InfoModalHeader='Greška!';
            this.InfoModalMessage='Niste dobro upisali razultat!';
            this.infoDialogStyle=[{ width: '40rem' }];
            this.displayInfoDialog= true;

     }else{
        
           const baseUrl = localStorage.getItem("_rootRestUrl");

           let formData = JSON.stringify({
                id_dogovoreno:this.id_dogovoreno,
                id_pobjednika:this.id_pobjednika,
                rezultat_opisno:this.rezultat_opisno,
                rez_1set_izazivac:this.rez_1set_izazivac,
                rez_2set_izazivac:this.rez_2set_izazivac,
                rez_3set_izazivac:this.rez_3set_izazivac,
                rez_1set_protivnik:this.rez_1set_protivnik,
                rez_2set_protivnik:this.rez_2set_protivnik,
                rez_3set_protivnik:this.rez_3set_protivnik
           });
       
         let config = {
          headers: {
            "Content-Type": "application/json",
          }};
        

          axios
     
          .post(baseUrl + "ajax_upisRezultata.php",formData, config )
          .then((response) => {

          if(response.data['ok']=='ok'){
              this.provjeriKogaMozeIzazvati();
              this.displayInfoDialog= true;
              this.prikaziDogovoren=false;
              this.dialogUpisRezultata=false;
              this.dialogEditRezultata=false;
              this.InfoModalHeader='Info';
              this.InfoModalMessage='Rezultat je spremljen';
              this.isLoading = false; 

              this.dajRaspored();

          }else{
           
           
              this.displayInfoDialog=true;
              this.InfoModalHeader='Greška!';
              this.InfoModalMessage='Došlo je do pogreške kod spremanja razultata!';
              this.isLoading = false;
              this.dialogUpisRezultata=false;
              this.dialogEditRezultata=false;
          }
                              
            
          })
          
            .catch(function () {
            // handle error
                          
              
          
            });






     }


  },


  racunajRezultat(){

//eh sad izračunazi tko je pobjednik:

 if( this.predao_protivnik == false  &&  this.predao_izazivac == false){

this.id_pobjednika = '';

var setovaIzazivac = 0; 
      var setovaprotivnik = 0;
      if(this.rez_1set_izazivac != this.rez_1set_protivnik){
        if(this.rez_1set_izazivac > this.rez_1set_protivnik){
          setovaIzazivac ++;
        } else{
          setovaprotivnik ++;
        }
      }

      if(this.rez_2set_izazivac != this.rez_2set_protivnik){
        if(this.rez_2set_izazivac > this.rez_2set_protivnik){
          setovaIzazivac ++;
        } else{
          setovaprotivnik ++;
        }
      }

      if(setovaIzazivac == 2 || setovaprotivnik == 2){
        this.disableTreciSet = true

      }

      if(this.rez_3set_izazivac != this.rez_3set_protivnik){
        if(this.rez_3set_izazivac > this.rez_3set_protivnik){
          setovaIzazivac ++;
        } else{
          setovaprotivnik ++;
        }
      }

      


      if(setovaIzazivac > setovaprotivnik){
        this.kliknuo_izazivaca = true;
        this.kliknuo_protivnika = false;
      }else if (setovaIzazivac < setovaprotivnik){
        this.kliknuo_izazivaca = false;
        this.kliknuo_protivnika = true;
      }else{
        this.kliknuo_izazivaca = false;
        this.kliknuo_protivnika = false;
      }

      if((setovaIzazivac == 1 && setovaprotivnik == 1) || (setovaIzazivac+setovaprotivnik) > 2){
        this.disableTreciSet = false;

      }

    
      this.rezultat_opisno = '';

      this.rezultat_opisno += this.rez_1set_izazivac + ":" + this.rez_1set_protivnik +", ";
      this.rezultat_opisno += this.rez_2set_izazivac + ":" + this.rez_2set_protivnik +"";

      if(setovaIzazivac + setovaprotivnik == 3){
        this.rezultat_opisno +=", " + this.rez_3set_izazivac + ":" + this.rez_3set_protivnik +"";
      }

      this.rezultat_opisno += '';

      if(setovaIzazivac > setovaprotivnik){
        this.id_pobjednika = this.upis_id_izazivaca;
      }else if(setovaIzazivac == setovaprotivnik){
        this.id_pobjednika = '';
      }else{
        this.id_pobjednika = this.upis_id_protivnika;
      }

      if( this.kliknuo_izazivaca == true){
        this.id_pobjednika = this.upis_id_izazivaca;
      }
      
      if( this.kliknuo_protivnika == true){
        this.id_pobjednika = this.upis_id_protivnika;
      }

    }

  },

  clickCheckbox(koji){

    this.kliknuo_izazivaca=false;
    this.kliknuo_protivnika=false;

    var netkojepredao = false;

    if(koji==1){
     
          if(!this.predao_izazivac==true){
            this.predao_protivnik = false;
                this.id_pobjednika = this.upis_id_protivnika; // pobijedio je protivnik jer je izazivač predao
                
                this.kliknuo_izazivaca=false;
                this.kliknuo_protivnika=true;
                this.rezultat_opisno = '(W.O.)';
                netkojepredao = true;
          }
    }

    if(koji==2){
     
     if(!this.predao_protivnik==true){
           this.predao_izazivac = false;
           this.id_pobjednika = this.upis_id_izazivaca; // pobijedio je protivnik jer je izazivač predao
           
           this.kliknuo_izazivaca=true;
           this.kliknuo_protivnika=false;
           this.rezultat_opisno = '(W.O.)';
           netkojepredao = true;
     }
    }




     if(netkojepredao == false){
      this.predao_protivnik = false;
      this.predao_izazivac = false;
      this.id_pobjednika = '';
      this.racunajRezultat();
     }
    
   





   
  },

      async dajRaspored(){
           
             const baseUrl = localStorage.getItem("_rootRestUrl");

              let formData = JSON.stringify({"piramida":this.piramidaID});

              let config = {
                headers: {
                  "Content-Type": "application/json",
              }};



            await axios
              .get(baseUrl + "raspored.php?piramida="+this.piramidaID+"",formData, config )
              .then((response) => {
                                
                this.raspored = response.data; 
             })
              
              .catch((error) => {
            
                    if(error.response){
                        this.InfoModalHeader='Info';
                        this.InfoModalMessage='Došlo je do pogreške prilikom dohvata rasporeda piramide.';
                        this.infoDialogStyle=[{ width: '40rem' }];
                        this.displayInfoDialog= true;
                       
                    
                    }   
                   
                    this.isLoading = false;   
                   
             });
      },

      async dajFaraona(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID,"mjesec":this.mjesec,"godina":this.godina});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "faraon.php?piramida="+this.piramidaID+"&mjesec="+this.mjesec+"&godina="+this.godina+"",formData, config )
            .then((response) => {
                              
              this.faraon = response.data; 
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata rasporeda faraona.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    async dajFaraonaKomplet(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "faraon-komplet.php?piramida="+this.piramidaID+"",formData, config )
            .then((response) => {
                              
              this.faraonKomplet = response.data; 
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata rasporeda faraona ukupno.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },


    async dajCezara(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID,"mjesec":this.mjesec,"godina":this.godina});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "cezar.php?piramida="+this.piramidaID+"&mjesec="+this.mjesec+"&godina="+this.godina+"",formData, config )
            .then((response) => {
                              
              this.cezar = response.data; 
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata rasporeda cezara.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    async dajCezaraKomplet(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "cezar-komplet.php?piramida="+this.piramidaID,formData, config )
            .then((response) => {
                              
              this.cezarKomplet = response.data; 
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata rasporeda cezara ukupno.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    moveTopBarToTop(){
        //default search box is inside header of the datatable, must move to the top of the page
        $('#hereMoveTopBar').empty();
        $(".topLoginBar").detach().appendTo('#hereMoveTopBar');
    },

    async dajRating(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "rating.php?piramida="+this.piramidaID+"",formData, config )
            .then((response) => {
                              
              this.rating = response.data; 
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata ratinga.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    async dajOpisiPravila(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "opisipravila.php?piramida="+this.piramidaID+"",formData, config )
            .then((response) => {
                              
              this.opisIpravila = response.data.opis; 
             
           
             
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata opisa i pravila.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },
   
    async dajNovosti(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "novosti.php?piramida="+this.piramidaID+"",formData, config )
            .then((response) => {
                              
              this.novosti = response.data; 
             
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata novosti.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    async dajNovost(id){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID,"novost":id});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "novosti.php?piramida="+this.piramidaID+"&novost="+id,formData, config )
            .then((response) => {
                              
              this.novost = response.data; 
              this.prikaziNovost = true;
             
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata novosti.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },

    async dajSveKategorije(){
           
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "svekategorije.php?piramida="+this.piramidaID,formData, config )
            .then((response) => {
                              
              this.sveKategorije = response.data; 
           
             
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata kategorija.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },


   async dajPiramidu(){

              this.mozeIzazivati=false;
              this.isLoading = true;
              const baseUrl = localStorage.getItem("_rootRestUrl");

              let formData = JSON.stringify({});

              let config = {
                headers: {
                  "Content-Type": "application/json",
              }};



            await axios
              .get(baseUrl + "piramida.php",formData, config )
              .then((response) => {
                                
                this.piramida = response.data; 
                this.piramidaID = this.piramida[0]['id_piramide'];  

                 this.datum_aktivacije=this.piramida[0]['datum_aktivacije'];   

                this.nacrtajPiramidu();

                // treba mi još
                

                this.isLoading = false; 
            })
              
              .catch((error) => {
            
                    if(error.response){
                        this.InfoModalHeader='Info';
                        this.InfoModalMessage='Došlo je do pogreške prilikom dohvata piramide.';
                        this.infoDialogStyle=[{ width: '40rem' }];
                        this.displayInfoDialog= true;
                       
                    
                    }   
                   
                    this.isLoading = false;   
                   
             });

    },


    nacrtajPiramidu(){

   
      
    //var redPiramide=0;
    var otvorenRed = false
   
    var html = '';
    let  b=0;
   
    html += '<div class="holderPiramide">';
    for (let i = 0; i < this.piramida.length; i++) {

        if(i>0){
          b=(i-1)*1;
        }
           
           if((this.piramida[i]['red'] != this.piramida[b]['red']) || i==0 ){
              if( otvorenRed == true){
                html += '</div>';
               
              }
              
              otvorenRed = true;  
            
             
              html += '<div class="row justify-content-md-center redpiramide">';
             }
             var akoOdgodio = '';
             var titleOdgodio = '';
             if(this.piramida[i]['odgodio']=='true'){
                akoOdgodio = 'background-position:center;background-image: url(images/blokada.png);background-size: cover;';
                titleOdgodio = 'Odgodio do: '+this.piramida[i]['odgodio_do'];
             }
           //  akoOdgodio

            var classThisUser='background:'+this.piramida[i]['boja1_kategorije']+';color:'+this.piramida[i]['boja2_kategorije'];   
              if(this.piramida[i]['id_igraca'] == this.$store.getters.userId){
                 classThisUser = 'font-weight: bold; color: #472AD0; border: 2px solid red; background: white';
              }

             

            html +=   '<div style="'+classThisUser+'" class="blokpiramide text-center" style="z-index:'+(i+100)+'" id="block'+i+'"  uuid="'+this.piramida[i]['uuid']+'" idigraca="'+this.piramida[i]['id_igraca']+'"><div  id="blockInside'+i+'" uuid="'+this.piramida[i]['uuid']+'" idigraca="'+this.piramida[i]['id_igraca']+'" class="insideBlokaPiramide" title="'+titleOdgodio+'" style="'+akoOdgodio+'">'+(i+1)+'. '+this.piramida[i]['ime']+'<br>'+this.piramida[i]['prezime']+'</div></div>'; 
      
     
    } 

             if( otvorenRed == true){
              // html += '</div>';
              }

              html += '</div>';
             
    this.piramidaHTML = html;
   

    this.dajRaspored();
    this.dajFaraona();
    this.dajFaraonaKomplet();
    this.dajCezara();
    this.dajCezaraKomplet();
    this.dajRating();
    this.dajNovosti();
    this.dajOpisiPravila();
    this.dajSveKategorije();

    //if user is logged in then something?
    if (this.$store.getters.isAuthenticated) {
        //logiran je, idemo provjeriti koga može izazivati
        this.provjeriKogaMozeIzazvati();
    }

   

    },
    napisiKogaMoze(){
      this.showEditButton=false;

      //console.table(this.kogaMoze)
        if(this.kogaMoze[0]['nemoze']==undefined){
         
        
          if(this.kogaMoze[0]['stats_rezultata']==undefined){ 
            // može izazivati
            // nacrtaj koga može
              this.nacrtajKogaMozeIzazvati(this.kogaMoze, this.piramida);


          }else{

          
            //ima dogovoren meč
              if(this.kogaMoze[0]['stats_rezultata']==0){
               
                
                //koji još nije odigran
                  this.prikaziDogovoren = true;

                  this.upis_id_izazivaca = this.kogaMoze[0]['id_izazivaca'];
                  this.upis_id_protivnika = this.kogaMoze[0]['id_protivnika'];
                  this.upis_izazivac= this.kogaMoze[0]['izazivac'];
                  this.upis_protivnik = this.kogaMoze[0]['protivnik'];

                  this.id_dogovoreno =  this.kogaMoze[0]['id_dogovoreno'];


              }else{
              
                  //ima odigran meč
                  this.prikaziDogovorenOdigran = true;

               
              }

          }


        }else{
          //ne može izazivati
              //provjera jel može edit
                if(this.kogaMoze[0]['stats_rezultata']!=0 && this.kogaMoze[0]['obracunato']==0){
                  //ima odigran meč koji još nije orađen
                  //može edit:
                  this.upis_izazivac= this.kogaMoze[0]['upis_izazivac'];
                  this.upis_protivnik= this.kogaMoze[0]['upis_protivnik'];
                   this.rez_1set_izazivac = this.kogaMoze[0]['set1izazivac'];
                   this.rez_2set_izazivac = this.kogaMoze[0]['set2izazivac'];
                   this.rez_3set_izazivac = this.kogaMoze[0]['set3izazivac'];
                   this.rez_1set_protivnik = this.kogaMoze[0]['set1protivnik'];
                   this.rez_2set_protivnik = this.kogaMoze[0]['set2protivnik'];
                   this.rez_3set_protivnik = this.kogaMoze[0]['set3protivnik'];
                   this.upis_id_izazivaca = this.kogaMoze[0]['upis_id_izazivaca'];
                   this.upis_id_protivnika = this.kogaMoze[0]['upis_id_protivnika'];
                   this.id_dogovoreno = this.kogaMoze[0]['id_dogovoreno'];

                   this.racunajRezultat(); 
                   this.showEditButton=true;
                 }     

         
        
        
          this.prikaziAlert = true;
        }

    },

    nacrtajKogaMozeIzazvati(kogaMoze,svi){
    
     this.lista = [];
    
      $('.loptica').remove();

     for (let i = 0; i < svi.length; i++) {
        
       for (let j = 0; j < kogaMoze.length; j++) {
             if(kogaMoze[j]['id']==svi[i]['id_igraca']){
            
                      this.lista.push({'ime':svi[i]['ime'], 'prezime':svi[i]['prezime'],'mjesto':svi[i]['mjesto'], "id_igraca":svi[i]['id_igraca']});
                      this.mozeTogaIzazvati[svi[i]['id_igraca']] = true;
                  

            }
          }

      }

   
      this.mozeIzazivati=true;
        // može izazivati onda nacrtaj loptice:
        this.nacrtajLoptice();
    },

    
    nacrtajLoptice(){

      for (let j = 0; j < this.lista.length; j++) {
        $('#blockInside'+(this.lista[j]['mjesto']-1)).parent().append('<img src="/images/teniskaloptica.png" class="loptica">');
            
      }


    },



    async provjeriKogaMozeIzazvati(){
          
      
           const baseUrl = localStorage.getItem("_rootRestUrl");

            let formData = JSON.stringify({"piramida":this.piramidaID});

            let config = {
              headers: {
                "Content-Type": "application/json",
            }};

          await axios
            .get(baseUrl + "kogaMozeIzazvati.php?piramida="+this.piramidaID+"&igrac="+this.$store.getters.userId,formData, config )
            .then((response) => {
                              
              this.kogaMoze = response.data; 
              this.napisiKogaMoze();
             
           })
            
            .catch((error) => {
          
                  if(error.response){
                      this.InfoModalHeader='Info';
                      this.InfoModalMessage='Došlo je do pogreške prilikom dohvata protivnika.';
                      this.infoDialogStyle=[{ width: '40rem' }];
                      this.displayInfoDialog= true;
                     
                  
                  }   
                 
                  this.isLoading = false;   
                 
           });
    },



       clickNaNovost(id){
             this.dajNovost(id);

    },

    mijenjaDatume(){
      this.dajFaraona();
      this.dajCezara();

    },

    // click na block piramide
    click(e) {

          if(e.target.id){
        
              var idgraca = $('#'+e.target.id).attr('uuid');
              if(idgraca!=undefined){
                this.dajPodatkeIgraca(idgraca); // ID igraca na kojeg je kliknuto !
              }
          }
      },


      async dajPodatkeIgraca(idgraca){

        const baseUrl = localStorage.getItem("_rootRestUrl");

        let formData = JSON.stringify({"idgraca":idgraca});

        let config = {
          headers: {
            "Content-Type": "application/json",
        }};

        await axios
        .get(baseUrl + "ajax_dajPodatkeIgraca.php?idgraca="+idgraca,formData, config )
        .then((response) => {
                          
          this.podaciIgraca = response.data;
          this.prikaziDetaljeIgraca();          
        
        })

        .catch((error) => {

              if(error.response){
                  this.InfoModalHeader='Info';
                  this.InfoModalMessage='Došlo je do pogreške prilikom dohvata podataka igrača.';
                  this.infoDialogStyle=[{ width: '40rem' }];
                  this.displayInfoDialog= true;
                
              
              }   
            
              this.isLoading = false;   
            
        });

      },

      izazivaIgraca(idIgraca, ime, prezime){
          this.idKogaIzaziva = idIgraca;
          this.imeKogaIzaziva = ime;
          this.prezimeKogaIzaziva = prezime;
          this.displayPotvrdaDialog = true;


      },

      async stvarnoIzaziva(){

          const baseUrl = localStorage.getItem("_rootRestUrl");

          let formData = JSON.stringify({"idIz":this.piramidaID,"idPr":this.mjesec});

          let config = {
            headers: {
              "Content-Type": "application/json",
          }};

          await axios
          .get(baseUrl + "ajax_izazov.php?idIz="+this.$store.getters.userId+"&idPr="+this.idKogaIzaziva,formData, config )
          .then(() => {
             
           this.displayPotvrdaDialogOk=true;
           this.modalDetaljiIgraca=false;
           this.displayPotvrdaDialog=false;

         
           this.kogaMoze = []; // izazvao je nekoga pa moramo poništiti kogaMože da ne bi bilo greške da opet nekoga može izazivati
          
           
                
                this.nacrtajKogaMozeIzazvati(this.kogaMoze, this.piramida);
                this.provjeriKogaMozeIzazvati();
                this.mozeIzazivati = false;
                this.dajRaspored();

          })

          .catch((error) => {

                if(error.response){
                    this.InfoModalHeader='Info';
                    this.InfoModalMessage='Došlo je do pogreške prilikom izazivanja.';
                    this.infoDialogStyle=[{ width: '40rem' }];
                    this.displayInfoDialog= true;
                  
                
                }   
              
                this.isLoading = false;   
              
          }); 



      },




      prikaziDetaljeIgraca(){
        this.modalDetaljiIgraca = true;

        this.postotakPobjeda = "fdfd";

        var ukupno = this.podaciIgraca['utakmica'];
        var pobjeda = this.podaciIgraca['pobjeda'];

        var postotak = ((pobjeda/ukupno)*100);
        postotak = postotak.toFixed(2);
        this.postotakPobjeda = postotak+"%";
  
         ///

        var  ukupno2 = this.podaciIgraca['ukupnoutakmica'];
        var  pobjeda2 = this.podaciIgraca['ukupnopobjeda'];
   

       var   postotak2 = ((pobjeda2/ukupno2)*100);
         postotak2 = postotak2.toFixed(2);
         this.postotakPobjedaUkupno = postotak2+"%";




      },

    
    usernameKeyDown() {
      this.showUsernameAlert = false;
      if (this.username.length == 0) {
        this.showUsernameAlert = true;
        this.formIsValid = false;
        this.usernameMessage = "Please enter username";
      }
    },

    passwordKeyDown() {
      this.showPasswordAlert = false;
    },

    closeModal(id) {
      document.getElementById(id).click();
    },

    async submitForm() {
      this.showUsernameAlert = false;
      this.showPasswordAlert = false;
      this.formIsValid = true;
      this.usernameMessage = "";
      this.passwordMessage = "";

      if (this.username === "") {
        this.showUsernameAlert = true;
        this.formIsValid = false;
        this.usernameMessage = "Please enter username";
      }

    /*  if (this.password.length < 4) {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Password is too short";
      }*/

      if (this.password === "") {
        this.showPasswordAlert = true;
        this.formIsValid = false;
        this.passwordMessage = "Please enter password";
      }

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;
      this.closeModal("closeLoginModal"); //close login modal after submit;

      try {
        await this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
        });

        this.isLoading = false;
        this.closeModal("closeLoginModal");
        this.$router.replace("/");
      
    
    } catch (e) {

        this.closeModal("closeLoginModal"); //close login modal after submit;
        this.error = e.message || "Failed to authenticate.";

        alert(this.error);

        this.error = null;

        this.isLoading = false;
      }

      this.isLoading = false;
    },
  },
 

   mounted() {
  /* this.$store.dispatch('autoLogin');*/
     
  this.moveTopBarToTop();

      this.dajPiramidu();
  
      
      this.mjesec = (moment().month())+1;
      this.godina = moment().year();
      

      for (let i = 2019; i <= this.godina; i++) {
        this.svegodine.push({"godina":i,"value":i})
      } 
    


   
  },
};
</script>


<style>
@import "datatables.net-dt";

@import "primevue/resources/themes/mdc-light-deeppurple/theme.css"; 
@import "primeicons/primeicons.css   "; /*icons*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.p-component {
  font-family: 'Open Sans', sans-serif !important;
}


/* LOGIN PAGE */
.loginPageBackground {
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.topLoginBar {
  background-color: rgb(0, 0, 0, 0.1);
  min-height: 55px;
}
#mainAppTitle {
  font-size: 1.23rem;
  font-weight: 700;
}
.signInHref {
  color: #787878;
}

.loginPageBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.pagesHolder{
  background-color: rgb(255,255,255,0.8);
  backdrop-filter: blur(4px); /*This be the blur*/
  top:65px;
  position: relative;
  height:100vh;
}




.blokpiramide{
  border:1px solid #444;
  width:130px;
  height:45px;
  display:inline-block;
  position:relative;
  margin:1px;
  box-shadow: 1px 1px 1px #888;
  cursor:pointer;
  font-size:12px;
  
}
.blokpiramide:hover{background-color:#aaa}

.insideBlokaPiramide{
  position: absolute;
  top:-1px;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: calc(100% - 3px);
  z-index:2;
}


.redpiramide{top:-1px;position:relative;width: 100% !important;}

.prekrizeno{text-decoration: line-through;color:#999}
.boldano{font-weight: bold;color:#000099}

#dataTable, #dataTableFaraon, #dataTableCezar, #dataTableFaraonKomplet, #dataTableCezarKomplet, #dataTableRating{font-size:14px}


.p-datatable .p-paginator-bottom {
  font-size: 14px;
}
.p-paginator .p-paginator-pages .p-paginator-page {
 
  height: 2rem;
  width:2rem;
  min-width:2rem;
  border-radius: 50%;
  font-size: 14px;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {

  min-width: 2rem;
  height: 2rem;
  font-size: 14px;
 
}

.p-inputtext {
 
  padding: 0.4rem;
  padding-bottom: 0.4rem;
  
  font-size:14px
 
}

.p-paginator .p-dropdown {
 
  height: 2.2rem;
}
.p-hidden-accessible{display:none}

.p-tabview-panels{height:calc(100vh - 120px)}

td.centeredColumnContent{text-align: center;}

.p-datatable-scrollable .p-datatable-tbody > tr > td {display:table-cell}
.p-datatable-scrollable .p-datatable-thead > tr > th {display:table-cell}
.p-column-header-content{text-align: center;}

.p-datatable .p-column-header-content { display: inline-block;}
h5.datum {font-size:13px;color:#999}

.p-tabview .p-tabview-panels, .p-dialog-content {
  font-size: 0.9rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
   padding: 0.4rem 0.4rem;
  font-size: 0.9rem;
}

.holderPiramide{
  display:block;
  width:1296px;
  overflow-y: hidden;
  overflow-x: auto;
}


  .justify-content-md-center {
    justify-content: center !important;
  }

  @media only screen and (max-width: 575px) {
 .blokpiramide {width:89px;height:39px;}
 .holderPiramide{width:843px}
 .insideBlokaPiramide{font-size:0.7rem;}
}

@media only screen and (min-width: 576px) {
 .blokpiramide {width:89px}
 .holderPiramide{width:843px}
  #ssdd{ width: 843px;}
}
@media only screen and (min-width: 993px) {
 .blokpiramide {width:98px}
 .holderPiramide{width:935px}
 #ssdd{ width: 935px;}
}

@media only screen and (min-width: 1201px) {
 .blokpiramide {width:118px}
 .holderPiramide{width:1118px}
 #ssdd{ width: 1118px;}
}
@media only screen and (min-width: 1401px) {
 .blokpiramide {width:134px}
 .holderPiramide{width:1294px}
 #ssdd{ width: 1288px;}
}


.p-dialog-content {
   overflow-y: auto !important;
}

.p-button {
   padding: 0.38rem 0.75rem;
}

#dialogNovosti{height:calc(100vh - 100px)}
#dialogNovosti .p-dialog-content{height:calc(100vh - 100px)} 

.nemozeIzazvati{opacity: .5;}
.tajIgrac{font-weight: bold; color: #472AD0; border: 2px solid red; background: white}
.malinaslov{font-size: 0.9rem;}
.prikazMeca{font-size: 1.11rem;font-weight:bold;margin-bottom:8px}

.p-tabview .p-tabview-nav{overflow-x: auto;overflow-y: hidden;}

th.boldani{font-weight: bold !important;}
th.centrirano{text-align: center !important;}
td.centrirano{text-align: center !important;}
th.ulijevo{text-align: left !important;}
td.ulijevo{text-align: left !important;}

.unosRezultata{
  width:40px
}

.unosRezultata .p-inputnumber-input{width:32px}
.p-dialog-mask {backdrop-filter: blur(2px)}

.dialogUpisRezultata{overflow-x: auto;}

@media screen and (max-width: 1199px) {
 
  .dialogUpisRezultata .p-dialog-content,  .dialogUpisRezultata .p-dialog-header{

    width: 700px !important;
    }
}


.slikaigraca{
  height:100%;object-fit: cover;max-width:255px;
}
.tablica {width:100%;margin-bottom:30px}
.tablica td {border-bottom:1px solid #DDD;padding:3px;}
.tablica th {font-weight:bold;border-bottom:3px double #DDD;padding:3px;background:#eee}

#ssdd{
  width: 100%;
  overflow-x: auto;
 /* border: 1px solid black;*/
  height: calc(100vh - 200px);
}

.loptica{
  width:22px;height:22px;position:absolute;top:9px;left:1px;z-index:1
}

th {background:#e1fffceb}

@media only screen and (max-width: 993px) {
  .p-paginator-current{
    display:none 
  }
  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled.p-paginator-rpp-options{
     display:none}


     .p-paginator-bottom{position: absolute;}
}


</style>

